import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const GuidePage = () => (
	<Layout
		meta={{
			title: "Speaker Guide",
			description:
				"What to do as a speaker at Accento Digital.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="speaker-guide-intro" />
		<Snippet id="speaker-guide-hanging" />
		<Snippet id="speaker-guide-talk" />
		<Snippet id="speaker-guide-interactive" />
	</Layout>
)

export default GuidePage
